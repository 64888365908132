import React, { useState, useEffect } from "react";
import Header from './components/Header';
import Features from './components/Features';
import HowItWorks from './components/HowItWorks';
import ContactUs from './components/ContactUs';
import UserGallery from './components/UserGallery';
import Footer from './components/Footer';
import Testimonials from './components/Testimonials';
import Team from './components/Team';

import MovingParticles from './components/MovingParticles';
import Partners from './components/PartnersList';
import HomePage from './components/HomePage';
import Privacy from './components/PrivacyStatementModal';
import CookieConsentPopup from './components/CookieConsentPopup';
import AccreditedCompaniesList from "./components/Accredition";
import ReactGA from 'react-ga4';

ReactGA.initialize('G-KKJN12222P');

const FloatingBall = ({ onClick }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  // Handle scroll visibility
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      
      // Show when near top of page (home section)
      if (scrollPosition < windowHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const ballContainerStyle = {
    position: 'fixed',
    top: '180px',
    left: '32px',
    zIndex: 9999,
    display: 'block',
    pointerEvents: 'auto',
    opacity: isVisible ? 1 : 0,
    transform: `scale(${isVisible ? 1 : 0.5})`,
    transition: 'all 0.5s ease-in-out'
  };

  const ballStyle = {
    width: '80px', // Increased size
    height: '80px', // Increased size
    borderRadius: '50%',
    background: 'linear-gradient(135deg, #fbbf24 0%, #f59e0b 100%)',
    boxShadow: '0 4px 15px rgba(251, 191, 36, 0.5)',
    border: '2px solid rgba(255, 255, 255, 0.4)',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'transform 0.3s ease-in-out',
    animation: 'floatBall 2s ease-in-out infinite',
    transform: isHovering ? 'scale(1.1)' : 'scale(1)'
  };

  const handleClick = () => {
    window.location.href = "https://apps.hauteuar.com"; // Redirect to the desired subdomain
  };

  return (
    <div style={ballContainerStyle}>
      <div
        style={ballStyle}
        onClick={handleClick}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <span style={{ fontSize: '28px', marginBottom: '4px' }}>🎨</span>
        <span style={{ 
          fontSize: '12px', 
          fontWeight: 'bold', 
          color: 'white',
          textAlign: 'center',
          lineHeight: '1.2'
        }}>
          HauteU AR
          <br />
          AI Apps
        </span>

        {isHovering && (
          <div style={{
            position: 'absolute',
            top: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
            background: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            padding: '8px 12px',
            borderRadius: '6px',
            whiteSpace: 'nowrap',
            marginTop: '12px',
            fontSize: '14px',
            boxShadow: '0 2px 10px rgba(0,0,0,0.2)'
          }}>
            Try our new AI apps Analysis!
          </div>
        )}
      </div>
    </div>
  );
};

function App() {
  const scrollToFeature = () => {
    window.location.href = "https://apps.hauteuar.com"; // Redirect to the subdomain
  };


  return (
    <div className="Header">
      <Header className="fixed w-full bg-gray z-10" />
      <CookieConsentPopup />
      
      {/* Always show the floating ball */}
      <FloatingBall onClick={scrollToFeature} />
      
      <div className="rest-of-the-page">
        {/* Rest of your components... */}
        <MovingParticles />
        <HomePage />
        <Features />


        {/* Rest of your components... */}
        <Team />
        <HowItWorks />
        
        <Testimonials />
        
        <UserGallery />
        <AccreditedCompaniesList />
        <ContactUs />
        <Partners />
        <Footer />
        <Privacy />
      </div>
    </div>
  );
}

export default App;