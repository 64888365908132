import React from 'react';
import PartnerLogo from './PartnerLogo';
import './Accredited.css';

// Example accredited companies data
const img_src = process.env.PUBLIC_URL;
const accreditedCompanies = [
    { name: 'Elevate', logoUrl: img_src + '/Elevate-logo.png' },
    { name: 'Clic', logoUrl: img_src + '/clic-logo.png' },
   
];

function AccreditedCompaniesList() {
    return (
        <section className="accredited-section">
            <h1>Accredited Companies</h1>
            <div className="accredited-list">
                {accreditedCompanies.map((company) => (
                    <PartnerLogo key={company.name} name={company.name} logoUrl={company.logoUrl} />
                ))}
            </div>
        </section>
    );
}

export default AccreditedCompaniesList;
