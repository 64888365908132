import React from 'react';
import ReactPlayer from 'react-player';
import './HomePage.css'; // Import your CSS file
import styled from 'styled-components';

const WelcomeTitle = styled.h1`
  text-align: center;
  padding-top: 40px;

  @media (max-width: 768px) {
    padding-top: 100px;
  }
`;

const PoweredBy = styled.h2`
  text-align: center;
  padding-top: 10px;

  @media (max-width: 768px) {
    padding-top: 20px;
  }
`;

const HomePage = () => {
  const img_src1 = process.env.PUBLIC_URL + '/features1.png';
  const img_src2 = process.env.PUBLIC_URL + '/features2.png';
  const img_src3 = process.env.PUBLIC_URL + '/features3.png';
  const img_src4 = process.env.PUBLIC_URL + '/features5.png';
  const video_src = process.env.PUBLIC_URL + '/haute-u-AR.mp4';

  return (
    <div id="Home" className="home-page">
      <WelcomeTitle>Welcome to <i>Haute-u AR</i> Technologies</WelcomeTitle>
      <PoweredBy className="blinking-link">Powered by <i>Gen AI</i></PoweredBy>

      <main className="main-content">
        <section className="left-section">
          <h2>Smart Fashion: AI-Driven Styling & Virtual Try-On</h2>
          <p>
            Step into the future of fashion with Haute-u AR! Our AI-powered system curates outfit recommendations
            tailored to your skin tone, body measurements, and unique shape, ensuring a flawless look every time.
          </p>
          <p>
            Experience next-level virtual try-ons with ultra-realistic garment overlays and in-depth analysis.
            Say goodbye to guesswork and shop with confidence—right from your screen.
          </p>
          <button className="try-on-button" onClick={() => window.location.href = 'https://apps.hauteuar.com'}>
            Try On Now
          </button>
        </section>

        <section className="right-section">
          <h2>Explore the App in Action</h2>
          <div className="screenshot-container">
            <img src={img_src1} alt="Screenshot 1" className="screenshot" />
            <img src={img_src2} alt="Screenshot 2" className="screenshot" />
            <img src={img_src3} alt="Screenshot 3" className="screenshot" />
            <img src={img_src4} alt="Screenshot 4" className="screenshot" />
          </div>
        </section>
      </main>

      <div id="features" className="features-container" style={{ marginTop: '100px', padding: '20px' }}>
        <h1 style={{ textAlign: 'center' }}>Key Features</h1>
        <div className="feature-quadrants" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          <div className="feature" style={{ width: '300px', margin: '20px', textAlign: 'center' }}>
            <img src={process.env.PUBLIC_URL + '/skin-tone-icon.png'} alt="Skin Tone Analysis" style={{ width: '80px', height: '80px', marginBottom: '10px' }}/>
            <h2>Skin Tone-Based Fashion Recommendations</h2>
            <p>AI-driven color and style suggestions to complement your natural beauty.</p>
          </div>
          <div className="feature" style={{ width: '300px', margin: '20px', textAlign: 'center' }}>
            <img src={process.env.PUBLIC_URL + '/virtual-measurement-icon.png'} alt="Virtual Measurement" style={{ width: '80px', height: '80px', marginBottom: '10px' }}/>
            <h2>Virtual Body Measurement</h2>
            <p>Get precise measurements instantly for a perfect fit every time.</p>
          </div>
          <div className="feature" style={{ width: '300px', margin: '20px', textAlign: 'center' }}>
            <img src={process.env.PUBLIC_URL + '/body-type-icon.png'} alt="Body Type Analysis" style={{ width: '80px', height: '80px', marginBottom: '10px' }}/>
            <h2>Body Type Analysis</h2>
            <p>Personalized outfit recommendations based on your body shape.</p>
          </div>
          <div className="feature" style={{ width: '300px', margin: '20px', textAlign: 'center' }}>
            <img src={process.env.PUBLIC_URL + '/ai-chat-icon.png'} alt="AI Chat" style={{ width: '80px', height: '80px', marginBottom: '10px' }}/>
            <h2>AI Chat for Personalized Fashion Advice</h2>
            <p>Get expert guidance on outfits, styles, and trends tailored for you.</p>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '50px' }}>
        <div style={{ width: '75%', height: '60%', padding: '7px' }}>
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url={video_src}
              width="90%"
              height="60%"
              playing={true}
              muted={true}
              controls={true}
              loop={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
