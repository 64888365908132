// Desc: This file contains the Features component, which displays the key features of Haute-u AR.
import React from 'react';
import './Features.css';
import { FaPalette, FaTshirt, FaRulerCombined, FaSearch, FaComments, FaShoppingBag } from 'react-icons/fa';
import { MdOutlineColorLens } from "react-icons/md";


const Features = () => {
    
  return (
    <div id="features" className="features-container" style={{marginTop:"100px"}}>
      <h1 style={{textAlign: "center"}}>Features</h1>
      <div className="feature-quadrants">
        
        {/* Skin Tone Analysis */}
        <div className="feature">
        <MdOutlineColorLens size={60} className="feature-icon" />
          <h2>Skin Tone Analysis Powered by AI</h2>
          <p>
            Discover the perfect fashion choices for your skin tone! With Haute-u AR's AI-driven
            skin tone analysis, receive personalized fashion recommendations that enhance your natural beauty
            and suit your unique complexion.
          </p>
          <i>Analyze Your Skin Tone</i>
        </div>

        {/* AI Fashion Chat */}
        <div className="feature">
        <FaComments size={60} className="feature-icon" />
          <h2>AI Fashion Chat</h2>
          <p>
            Chat with our AI assistant to get personalized fashion advice based on your skin tone, body shape, and preferences.
            Receive tailored recommendations that match your style and help you look your best!
          </p>
          <i>Chat with AI for Fashion</i>
        </div>

        {/* Virtual Measurement */}
        <div className="feature">
        <FaRulerCombined size={60} className="feature-icon" />
          <h2>Virtual Measurement Powered by AI</h2>
          <p>
            Get your accurate virtual measurements in seconds! Haute-u AR uses advanced AI to capture your body measurements
            virtually, making online shopping easy and ensuring a perfect fit every time.
          </p>
          <i>Get Measured Virtually</i>
        </div>

        {/* Virtual Try-On */}
        <div className="feature">
        <FaTshirt size={60} className="feature-icon" />
          <h2>Virtual Try-On</h2>
          <p>
            Experience the thrill of trying on clothes without ever leaving your home.
            Haute-u AR's cutting-edge technology seamlessly overlays garments onto your
            body, allowing you to visualize styles, fits, and colors with remarkable
            accuracy.
          </p>
          <i>Try On Now</i>
        </div>

        {/* Diverse Style Exploration */}
        <div className="feature">
        <FaSearch size={60} className="feature-icon" />
          <h2>Diverse Style Exploration</h2>
          <p>
            Unleash your inner fashionista with Haute-u AR's extensive collection of
            clothing styles. Experiment with various trends, patterns, and designs to
            discover your perfect match. Find outfits that complement your unique
            personality and express your individual style.
          </p>
          <i>Explore Styles</i>
        </div>

        {/* Color Customization */}
        <div className="feature">
        <FaPalette size={60} className="feature-icon" />
          <h2>Color Customization</h2>
          <p>
            Haute-u AR empowers you to explore a spectrum of color options for each
            garment. Simply tap on your desired color, and witness the virtual outfit
            transform instantly. Find the perfect shade that flatters your complexion
            and complements your overall look.
          </p>
          <i>Customize Colors</i>
        </div>

        {/* Confident Shopping */}
        <div className="feature">
        <FaShoppingBag size={60} className="feature-icon" />
          <h2>Confident Shopping</h2>
          <p>
            Say goodbye to buyer's remorse! Haute-u AR eliminates the uncertainty
            associated with online shopping. By virtually trying on clothes beforehand,
            you can make informed decisions with greater confidence. Purchase garments
            knowing they'll look and fit you perfectly.
          </p>
          <i>Shop with Confidence</i>
        </div>

      </div>
    </div>
  );
};

export default Features;
